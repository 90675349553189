<template>
  <div class="page_recruitment" id="recruitment_list">
    <div class="rlzp_banner"></div>
    <div class="job_content">
      <div class="job_title">招聘专区</div>
      <div class="job_table_title">
        <el-table class="my-table"
            :data="tableData"
            style="width: 100%">
          <el-table-column
              prop="name"
              label="岗位名称"
              >
            <template #default="scope">
              <a style="color: black" @click="to_form('/consultation_information/edit')">{{scope.row.name}}</a>
            </template>
          </el-table-column>
          <el-table-column
              prop="address"
              label="工作地点"
              >
          </el-table-column>
          <el-table-column
              prop="jobtype"
              label="工作类型"
              >
          </el-table-column>
          <el-table-column type="expand" label="详情" width="150">
            <template slot-scope="props">
              <el-form label-position="right" inline class="demo-table-expand">
                <el-form-item label="岗位职责">
                  <div style="white-space: pre-wrap">{{ props.row.responsibility }}</div>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="job_staff_title"></div>
    <div class="job_staff">
      <div class="job_staff_content">
        <el-carousel :interval="4000" arrow="always" >
          <el-carousel-item>
            <div class="job_staff_img1"></div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="job_staff_img2"></div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="job_staff_img3"></div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="jmly">
      <router-link to="/consultation_information/edit">
        <div class="jmly_to"></div>
      </router-link>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/page.js";
import edit from "@/views/consultation_information/edit.vue";

export default {
  mixins: [mixin],
  components: {
    edit,
  },
  data() {
    return {
      tableData: [{
        name: '储备干部',
        address: '山东、北京、河南',
        jobtype:'全职',
        responsibility:'1、以“训战结合”的培训形式，进行门店运营管理的培训学习；\n'+
            '2、有过团队管理经验或餐饮工作经验；\n' +
            '3、强烈的责任感，有较强的实现个人价值的愿望，意依靠自己实现自己的人生理想；\n' +
            '4、积极进取、乐观开朗、有志向从事管理方面的工作。'
      },{
        name: '店长',
        address: '山东、北京、河南',
        jobtype:'全职',
        responsibility:'1、制定公司各项规章制度，并依照规章制度对店内员工进行管理；\n'+
            ' 2、负责依据产品的特点和风格制定合适的产品销售策略并组织实施；\n'+
            ' 3、指导店内员工做好食品点餐、货架、陈列以及补货、调货、退货等工作；\n'+
            ' 4、组织做好店内库存食品的防潮、变质、物品防盗等工作；\n'+
            ' 5、组织搞好店内卫生，营造整洁、舒适的就餐环境；\n'+
            ' 6、负责做好店内各项任务的分配工作，保证店内工作有序开展；\n'+
            ' 7、负责店铺内各项费用的成本控制工作；\n'+
            ' 8、负责门店各部门管理人员的选拔和考评工作。'
      },{
        name: '店员',
        address: '山东、北京、河南',
        jobtype:'全职',
        responsibility:'1、配合店长工作，服从店长或以上领导指挥，团结及善于帮助同事工作；\n'+
            ' 2、做好桌椅、餐厅卫生，准备各种用品；\n'+
            ' 3、迎接客人，介绍本店特色及经营性质；\n'+
            ' 4、及时安排客人入座，并留意客人及餐厅的一切状况；\n'+
            ' 5、及时处理工作中出现的疑问；\n'+
            ' 6、保障工作区域卫生、维持清洁。'
      },{
        name: '研发经理',
        address: '济南',
        jobtype:'全职',
        responsibility:'1、负责菜品研发及菜单的更新；\n' +
            '2、定期组织新品研发，创制新菜式，推出时令菜式；\n' +
            '3、定期进行菜品检查，保证菜品质量；\n' +
            '4、制定和优化菜品价格，并进行毛利分析；\n' +
            '5、供应加工中心管理，保证中心出品的时效性和出品质量；\n' +
            ' 6、团队管理与人才培养。'
      }, {
        name: '供应链经理',
        address: '济南',
        jobtype:'全职',
        responsibility:'1、建立健全物流服务商的管理规范、标准及运作流程；\n' +
            '2、制定线上物流管理体系；\n' +
            '3、管理物流服务商，确保物流服务商在管理体系内按照制定的规范和流程运作；\n' +
            '4、对现有系统和流程进行优化，以及系统和其他系统对接及数据输出的改善提升；\n' +
            '5、根据销售计划管理供货节奏，优化仓库利用率及库存周转率。'
      }, {
        name: '训练经理',
        address: '济南',
        jobtype:'全职',
        responsibility:'1、依据门店培训需求，将门店提出的问题进行整合，并寻找优秀案例进行萃取，转化为可推广的培训课件；\n' +
            '2、根据课程开发内容，输出各维度资料：调研、课件PPT、讲义、学员指南、手册等；\n' +
            '3、依据培训计划，追踪并记录市场培训工作的达成结果，并根据结果进行统计分析与反馈；\n' +
            '4、定期走访市场门店，追踪授课后学员的执行效果，定期与学员沟通，了解、评估训练现存问题点；\n' +
            '5、提报开发课程、评估课程是否符合市场运营需求、便于门店落地执行。'
      },]
    };
  },
  created() {
  },
  methods: {
    goAnchor(selector) {
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth"
      });
    }
  },
};
</script>

<style scoped>

.rlzp_banner {
  width: 100%;
  height: 100vh;
  background: url("../../assets/images/jrwm.jpg") no-repeat;
  background-size: 100% 100%;
}

.job_content {
  width: 80%;
  min-height: 60vh;
  margin: auto;
  margin-bottom: 15px;
}
.job_title {
  width: 30%;
  height: 10vh;
  font-size: xxx-large;
  font-weight: bolder;
  text-align: center;
  margin: auto;
}
.job_table_title {
  width: 100%;
}
.job_staff {
  width: 100%;
  min-height: 80vh;
}
.job_staff_content {
  width: 80%;
  height: 70%;
  margin: auto;
}
.job_staff_img1 {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/recruitment/ygxs1.jpg");
  background-size: 100% 100%;
}
.job_staff_img2 {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/recruitment/ygxs2.jpg");
  background-size: 100% 100%;
}
.job_staff_img3 {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/recruitment/ygxs3.jpg");
  background-size: 100% 100%;
}
.job_staff_title {
  width: 30%;
  height: 150px;
  margin: auto;
  background: url("../../assets/images/recruitment/ygxst.png") no-repeat;
  background-size: 100% 100%;
}
/deep/ .el-carousel__container {
  height: 490px;
}
@media (max-width: 1081px) {
  .rlzp_banner{
    width: 100%;
    height: 40vh;
    background: url("../../assets/images/jrwm.jpg") no-repeat;
    background-size: 100% 100%;
  }
  .jmly_to {
    height: 50%;
    width: 50%;
    background: url("../../assets/images/join/hzzx.png") no-repeat;
    background-size: 100% 100%;
  }
  .jmly {
    height: 68px !important;
    width: 130px;
    z-index: 11111112;
    top: 20%;
    right: -45px;
    position: fixed;
    margin-top: -34px;
    margin-left: 0px;
  }
  .job_title{
    width: 100%;
    height: 7vh;
    font-size: xx-large;
    font-weight: bolder;
    text-align: center;
    margin: auto;
  }
  .job_staff_title {
    width: 30%;
    height: 100px;
    margin: auto;
    background: url("../../assets/images/recruitment/ygxst.png");
    background-size: 100% 100%;
  }
  /deep/ .el-carousel__container {
    height: 280px;
  }
  .job_staff {
    width: 100%;
    min-height: 45vh;
  }
  .job_staff_content[data-v-35dff0cb] {
    width: 90%;
    height: 70%;
    margin: auto;
  }

}
</style>
<style>
.my-table th{
  border:none;
}
.my-table td,.my-table th.is-leaf {
  border:none;
}
/* 表格最外边框 */
.el-table--border, .el-table--group{
  border: none;
}
/* 头部边框 */
.my-table thead tr th.is-leaf{
  border-bottom: 1px solid #EBEEF5;
  border-right: none;
}
.my-table thead tr th:nth-last-of-type(2){

}
/* 表格最外层边框-底部边框 */
.el-table--border::after, .el-table--group::after{
  width: 0;
}
.my-table::before{
  width: 0;
}
.my-table .el-table__fixed-right::before,.el-table__fixed::before{
  width: 0;
}
/* 表格有滚动时表格头边框 */
.el-table--border th.gutter:last-of-type {
  border: 1px solid #EBEEF5;
  border-left: none;
}
.el-table tr {
  font-size: large;
}
.demo-table-expand {
  font-weight: 0;
}
.demo-table-expand label {
  width: 90px;
  font-weight: bolder;
  color: #99a9bf;
}
.jmly {
  height: 68px !important;
  width: 130px;
  z-index: 11111112;
  top: 20%;
  right: 0px;
  position: fixed;
  margin-top: -34px;
  margin-left: 0px;
}
.jmly_to {
  height: 100%;
  width: 100%;
  background: url("../../assets/images/join/hzzx.png") no-repeat;
  background-size: 100% 100%;
}
</style>
