<template>
  <div class="back_ground">
    <div class="diy_edit page_consultation_information" id="consultation_information_edit">
      <div class='warp' style="margin-top: 100px">
        <div class='container'>
          <div class='row diy_edit_content_box' style="margin-bottom: 20px;padding: 50px">
            <div class="input_content">
              <div class="diy_title">
							<span>
								姓名:
							</span>
              </div>
              <!-- 文本 -->
              <div class="diy_field diy_text">
                <input type="text" id="form_name_of_home_stay" v-model="form['name_of_home_stay']"
                       placeholder="请输入您的姓名" autocomplete="off"/>
              </div>
            </div>
            <div class="input_content">
              <div class="diy_title">
							<span>
								联系方式:
							</span>
              </div>
              <!-- 文本 -->
              <div class="diy_field diy_text">
                <input type="tel" id="form_home_stay_specifications" v-model="form['home_stay_specifications']"
                       placeholder="请输入您的联系方式" autocomplete="off"/>
                <!--              <span v-else-if="$check_field('get','home_stay_specifications')">{{ form['${obj.name}'] }}</span>-->
              </div>
            </div>
            <div class="input_content">
              <div class="diy_title">
							<span>
								合作城市:
							</span>
              </div>
              <!-- 文本 -->
              <div class="diy_field diy_text">
                <input type="text" id="form_merchant_name" v-model="form['merchant_name']" placeholder="请输入合作城市"
                       autocomplete="off"/>
                <!--              <span v-else-if="$check_field('get','merchant_name')">{{ form['${obj.name}'] }}</span>-->
              </div>
            </div>
            <div class="input_content">
              <div class="diy_title">
							<span>
								咨询日期:
							</span>
              </div>
              <!-- 日期 -->
              <div class="diy_field diy_date">
                <input type="date" :disabled="disabledObj['consultation_date_isDisabled']" id="form_consultation_date"
                       v-model="form['consultation_date']" placeholder="请输入咨询日期"/>
              </div>
            </div>
            <div class="">
              <div class="diy_title">
							<span>
								留言内容:
							</span>
              </div>
              <!-- 多文本 -->
              <div class="">
              <textarea id="form_consultation_content" v-model="form['consultation_content']"
                        placeholder="请输入您的留言" class="liuyan"
                        :disabled="disabledObj['consultation_content_isDisabled']"/>
                <!--              <span v-else-if="$check_field('get','consultation_content')">{{ form['${obj.name}'] }}</span>-->
              </div>
            </div>

            <div class="submit_bt">
              <el-button type="danger" @click="submit()" round>提交</el-button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/page.js";

export default {
  mixins: [mixin],
  components: {},
  data() {
    return {
      url_get_obj: "~/api/consultation_information/get_obj?",
      url_add: "~/api/consultation_information/add?",
      url_set: "~/api/consultation_information/set?",

      // 登录权限
      // oauth: {
      //   "signIn": true,
      //   "user_group": []
      // },

      // 查询条件
      query: {
        "name_of_home_stay": "",
        "room_type": "",
        "home_stay_specifications": "",
        "merchant_users": 0,
        "merchant_name": "",
        "merchant_address": "",
        "consult_users": 0,
        "consultation_content": "",
        "consultation_date": "",
        "consultation_information_id": 0,
      },

      obj: {
        "name_of_home_stay": '', // 民宿名称
        "room_type": '', // 房间类型
        "home_stay_specifications": '', // 民宿规格
        "merchant_users": 0, // 商家用户
        "merchant_name": '', // 商家名称
        "merchant_address": '', // 商家地址
        "consult_users": 0, // 咨询用户
        "consultation_content": '', // 咨询内容
        "consultation_date": new Date().getTime(),
        "examine_state": "未审核",
        "examine_reply": "",
        "consultation_information_id": 0,
      },

      // 表单字段
      form: {
        "name_of_home_stay": '', // 民宿名称
        "room_type": '', // 房间类型
        "home_stay_specifications": '', // 民宿规格
        "merchant_users": 0, // 商家用户
        "merchant_name": '', // 商家名称
        "merchant_address": '', // 商家地址
        "consult_users": 0, // 咨询用户
        "consultation_content": '', // 咨询内容
        "consultation_date": new Date().getTime(),
        "examine_state": "未审核",
        "examine_reply": "",
        "consultation_information_id": 0,
      },
      disabledObj: {
        "name_of_home_stay_isDisabled": false,
        "room_type_isDisabled": false,
        "home_stay_specifications_isDisabled": false,
        "merchant_users_isDisabled": false,
        "merchant_name_isDisabled": false,
        "merchant_address_isDisabled": false,
        "consult_users_isDisabled": false,
        "consultation_content_isDisabled": false,
        "consultation_date_isDisabled": false,
      },

      // 用户列表
      list_user_merchant_users: [],
      // 用户列表
      list_user_consult_users: [],

      // ID字段
      field: "consultation_information_id",

    }
  },
  methods: {


    /**
     * 获取商家用户用户列表
     */
    async get_list_user_merchant_users() {
      // if(this.user_group !== "管理员" && this.form["merchant_users"] === 0) {
      //     this.form["merchant_users"] = this.user.user_id;
      // }
      var json = await this.$get("~/api/user/get_list?user_group=商家用户");
      if (json.result && json.result.list) {
        this.list_user_merchant_users = json.result.list;
      } else if (json.error) {
        console.error(json.error);
      }
    },


    /**
     * 获取普通用户用户列表
     */
    async get_list_user_consult_users() {
      // if(this.user_group !== "管理员" && this.form["consult_users"] === 0) {
      //     this.form["consult_users"] = this.user.user_id;
      // }
      var json = await this.$get("~/api/user/get_list?user_group=普通用户");
      if (json.result && json.result.list) {
        this.list_user_consult_users = json.result.list;
      } else if (json.error) {
        console.error(json.error);
      }
    },
    async get_user_session_consult_users() {
      var _this = this;
      var json = await this.$get("~/api/user_group/get_obj?name=普通用户");
      if (json.result && json.result.obj) {
        var source_table = json.result.obj.source_table;
        var user_id = _this.$store.state.user.user_id;
        if (user_id) {
          var url = "~/api/" + source_table + "/get_obj?"
          this.$get(url, {"user_id": _this.$store.state.user.user_id}, function (res) {
            if (res.result && res.result.obj) {
              var arr = []
              for (let key in res.result.obj) {
                arr.push(key)
              }
              var arrForm = []
              for (let key in _this.form) {
                arrForm.push(key)
              }
              _this.form["consult_users"] = user_id
              _this.disabledObj['consult_users' + '_isDisabled'] = true
              for (var i = 0; i < arr.length; i++) {
                if (arr[i] !== 'examine_state' && arr[i] !== 'examine_reply') {
                  for (var j = 0; j < arrForm.length; j++) {
                    if (arr[i] === arrForm[j]) {
                      if (arr[i] !== "consult_users") {
                        _this.form[arrForm[j]] = res.result.obj[arr[i]]
                        _this.disabledObj[arrForm[j] + '_isDisabled'] = true
                        break;
                      }
                    }
                  }
                }
              }
            }
          });
        }
      } else if (json.error) {
        console.error(json.error);
      }
    },


    /**
     * 修改文件
     * @param { Object } files 上传文件对象
     * @param { String } str 表单的属性名
     */
    change_file(files, str) {
      var form = new FormData();
      form.append("file", files[0]);
      this.$post("~/api/consultation_information/upload?", form, (res) => {
        if (res.result) {
          this.form[str] = res.result.url;
        } else if (res.error) {
          this.$toast(res.error.message);
        }
      });
    },

    /**
     * 获取对象后获取缓存表单
     * @param {Object} json
     * @param {Object} func
     */
    get_obj_before(param) {
      var form = $.db.get("form");
      // if (form) {
      //   delete(form.examine_state)
      //   delete(form.examine_reply)
      //   this.obj = $.push(this.obj ,form);
      // 	this.form = $.push(this.form ,form);
      // }
      // var arr = []
      // for (let key in form) {
      // 	arr.push(key)
      // }
      // for (var i=0;i<arr.length;i++){
      // 	this.disabledObj[arr[i] + '_isDisabled'] = true
      // }
      if (form) {
        var arr = []
        for (let key in form) {
          arr.push(key)
        }
        var arrForm = []
        for (let key in this.form) {
          arrForm.push(key)
        }
        for (var i = 0; i < arr.length; i++) {
          if (arr[i] !== 'examine_state' && arr[i] !== 'examine_reply') {
            for (var j = 0; j < arrForm.length; j++) {
              if (arrForm[j] === arr[i]) {
                this.form[arrForm[j]] = form[arr[i]]
                this.obj[arrForm[j]] = form[arr[i]]
                this.disabledObj[arrForm[j] + '_isDisabled'] = true
                break;
              }
            }
          }
        }
      }
      if (JSON.stringify(this.form["consultation_date"]).indexOf("-") === -1) {
        this.form["consultation_date"] = this.$toTime(parseInt(this.form["consultation_date"]), "yyyy-MM-dd")
      }

      $.db.del("form");
      return param;
    },

    /**
     * 获取对象后获取缓存表单
     * @param {Object} json
     * @param {Object} func
     */
    get_obj_after(json, func) {
      // var form = $.db.get("form");
      // var obj = Object.assign({} ,form ,this.obj);
      // if (obj) {
      //   delete(obj.examine_state)
      //   delete(obj.examine_reply)
      // 	this.obj = $.push(this.obj ,obj);
      // }
      // if (form) {
      //   delete(form.examine_state)
      //   delete(form.examine_reply)
      // 	this.form = $.push(this.form ,form);
      // }
      if (func) {
        func(json);
      }
    },

  },
  created() {
    this.get_list_user_merchant_users();
    this.get_user_session_consult_users();
    this.get_list_user_consult_users();
  },
}
</script>

<style>

.diy_edit_content_box {
  padding: 20px 60px;
}
.diy_edit .row .diy_text input {
  color: black;
}

.back_ground {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -20vh;
  background: url("../../assets/images/numbg.jpg");
}

.input_content {
  margin: 20px;
}

.submit_bt {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.liuyan {
  width:500px;
  height: 200px;
}
@media (max-width: 1081px) {
  .container {
    width: 100%;
    max-width: 16rem;
    margin-right: auto;
    margin-left: auto;
  }
  .liuyan {
    width:200px;
    min-height: 200px;
  }
}
</style>
